export const userGetters = {
  getTest(state) {
    return state.test
  },
  getLoggedIn(state) {
    return state.loggedin
  },
  getDisplayName: (state) => {
    return state.displayname
  },
  getAccessToken(state) {
    return state.accesstoken
  },
  getIdToken(state) {
    return state.idtoken
  },
  getAccessTokenExpires(state) {
    return state.accesstokenexpires
  },
  getUserId(state) {
    return state.userid
  },
  getEmail(state) {
    return state.email
  },
  getService(state) {
    return state.service
  },
  getAffilitation(state) {
    return state.affilitation
  },
  getServerAccessToken(state) {
    return state.serveraccesstoken
  },
  getSecondaryUser(state) {
    return state.secondaryuser
  },
  getLanguage(state) {
    return state.language
  },
  getPrimaryUser(state) {
    return state.primaryuser
  },
  getEngageUser(state) {
    console.info('getEngageUser called (%j)', state.engageuser)
    return state.engageuser
  }
}
