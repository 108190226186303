import { encodeLoad, decodeLoad } from '@/lib/base64.js'

import { adminStore } from '@/stores/adminstore.js'
import { CreateHypatiaJWE } from '@/lib/createJWT'
import dayjs from 'dayjs'

export const userActions = {
  isActiveService(serviceid) {
    //    return this.services.has(serviceid)
    let service = this.primaryuser.pu_service
    console.log(
      'isActiveService (%s) (%s) (%s)',
      service,
      serviceid,
      service === serviceid
    )
    return service === serviceid
  },
  setTest(text) {
    console.log('set TEST got called (%s)', text)
    this.test = text
  },
  setLoggedIn(status) {
    this.loggedin = status
  },
  setDisplayName(name) {
    this.displayname = name
  },
  //  setDataFrominfoUrl(payload) {
  logoutUser() {
    /* null all user variables and logout */
    this.loggedin = false
  },
  /* ********************* */
  /* ++  SELF REPORTING ++ */
  /* ********************* */
  async saveSelfReportingQuestionnaire(payload) {
    console.log('saveSelfReportingQuestionnaire got called (%j)', payload)
    return new Promise((resolve) => {
      const aStore = adminStore()
      let pUser = this.primaryuser
      let load = {
        primaryUserUid: pUser.uid,
        values: payload,
        language: this.language,
        testTime: dayjs().format()
      }
      console.log('LOAD IS ')
      console.log(load)
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(load)),
        p1: aStore.sid,
        memasp: true
      }
      console.log(pLoad)
      aStore
        .getAxiosConnection('memas/')
        .post('saveSelfReporting/selfreporting', pLoad)
        .then((result) => {
          console.log('Save SelfReporting returned (%j)', result)
          if (result.data.success) {
            resolve({ success: true })
          } else {
            resolve({ success: false, error: true })
          }
        })
    }) // promise
  },
  /* ********************* */
  /* PRIMARY USER LOGIN ++ */
  /* ********************* */
  async checkPrimaryKey(key) {
    console.log('check PRIMARY KEY got called (%s)', key)
    return new Promise((resolve) => {
      const aStore = adminStore()
      let pLoad = {
        mutate: encodeLoad(key),
        p1: aStore.sid,
        memasp: true
      }
      let instance = aStore.getAxiosConnection('memas/')
      instance
        .post('findPrimaryKey/findpk', pLoad)
        .then((response) => {
          console.log('Find primary key respons (%j)', response)
          let responseData = response.data.data
          console.log(responseData)
          if (responseData.noresult) {
            this.primaryuser = {}
            resolve({ success: false, error: true })
          } else if (response.data.success) {
            this.primaryuser = responseData.query[0] || {}
            this.language = this.primaryuser.pu_language
            aStore.lang = this.language
            localStorage.setItem('lang', this.language)
            resolve({ success: true, data: responseData.query[0] })
          } else {
            this.primaryuser = {}
            resolve({ success: false, error: true })
          }
        })
        .catch((err) => {
          console.log('** ERROR ** Find primary user respons (%j)', err)
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
  setPrimaryUser(payload) {
    this.primaryuser = payload
    this.displayname = payload.pu_displayName
    this.service = payload.pu_service
    this.language = payload.pu_language
    this.userid = payload.pu_userid
    this.email = payload.pu_email
  },
  getRandomKey(numDigits) {
    let randomKey = ''
    let randomMax = randomDigits.length
    // console.log('RANDOM MAX IS (%s)', randomMax)
    if (numDigits) {
      for (let i = 0; i < numDigits; i++) {
        let random = Math.floor(Math.random() * randomMax)
        // console.log('RANDOM IS (%s) (%s)', random, randomDigits[random])
        randomKey = randomKey + randomDigits[random]
      }
    } else {
      randomKey = this.getRandomKey(4)
    }
    return randomKey
  },
  getPrimaryUserData() {
    return this.primaryuser
  },
  async findPrimaryUser(userid) {
    console.log('findPrimaryUser got called (%s)', userid)
    if (this.serveraccesstoken === '') {
      this.serveraccesstoken = localStorage.getItem('token')
    }
    return new Promise((resolve) => {
      const aStore = adminStore()
      let pLoad = {
        mutate: encodeLoad(userid)
        //  p1: aStore.sid,
        //  memasp: true
      }
      let instance = aStore.getAxiosConnection('memas/')
      instance
        .post('getPrimaryUser/autoLoadPrimary', pLoad)
        .then((response) => {
          let responseData = response.data
          console.log(
            'get Primary user respons (%s) (%j)',
            responseData.success,
            responseData.data.query
          )
          if (responseData.success) {
            this.primaryuser = responseData.data.query[0]
            let lang = this.primaryuser.pu_language || 'en'
            this.language = lang
            let services = this.primaryuser.pu_hasServices || []
            if (services.length > 0) {
              for (let i = 0; i < services.length; i++) {
                aStore.setSelectedService(services[i].service_id)
              }
            } else {
              let allServices = aStore.getServices()
              for (let i = 0; i < allServices.length; i++) {
                aStore.setSelectedService(allServices[i].label)
              }
            }
            resolve({ success: true, data: responseData.data.query[0] })
          } else {
            this.primaryuser = {}
            resolve({ success: false, error: true, errorMsg: response })
          }
        })
        .catch((err) => {
          console.log('** ERROR ** Get primary user respons (%j)', err)
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
  async memasLogin(payload) {
    console.log('MEMAS LOGIN got called !')
    console.log(JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      const aStore = adminStore()
      // this.accesstoken = payload.bearerToken
      // this.accesstokenexpires = payload.expires
      // this.idtoken = payload.id_token
      // localStorage.setItem('tokenexpire', payload.expires)
      this.displayname = payload.userName
      this.userid = payload.userid // Google email
      this.email = payload.email
      this.affiliation = 'KardeMEMAS'
      payload.affiliation = this.affiliation
      // context.dispatch('fixServerURL')
      // payload.encodedAccessToken = payload.bearerToken
      // payload.eduPersonAffiliation = 'KardeMEMAS'
      // let publicKey = context.getters.getPublicKey // for client use
      //    let privateKey = context.getters.getPrivateKey // for serveruse !
      createJwtTokenForClient(payload)
        .then((result) => {
          console.info('MEMAS LOGIN / JWTTOKEN ')
          console.log(result)
          // last part of the token is the secret key..
          // context.commit('setAcessToken', result.accessToken)
          this.accesstoken = result
          this.serveraccesstoken = result.accessToken
          this.publickey = result.publicKey
          this.appsecret = result.secret
          aStore.sid = result.sid
          localStorage.setItem('sid', result.sid)
          localStorage.setItem('token', result.accessToken)
          this.accesstokenexpires = payload.expires
          this.loggedin = true
          console.log('createJwtTokenForClient returned')
          console.log(result)
          resolve({
            success: true,
            error: false
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createJWT Token For Client had some problems : ' +
              error
          )
          reject(
            new Error(
              '*** ERROR *** createJWT Token For Client had some problems : ' +
                error
            )
          )
        })
    })
  },
  async getPrimaryHelpVideos() {
    console.log(
      'getPrimaryHelpVideos user got called (%s)',
      this.primaryuser.uid
    )
    let pUser = this.primaryuser
    // console.log(secondaryUser)
    return new Promise((resolve) => {
      const aStore = adminStore()
      let query = `{
        query(func: uid(${pUser.uid})) {
          uid
          dailyHelp: pu_hasDailyHelp {
            uid
            dh_created
            dh_title
            dh_video
            dh_videoimage
          }
        }
      } `
      let pLoad = {
        query: encodeLoad(query),
        p1: aStore.sid,
        memasp: true
      }
      console.log(query)
      let instance = aStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getPrimaryHelpVideos', pLoad).then((response) => {
        console.log(response)
        let responseData = response.data
        console.log(
          'getPrimaryHelpVideos respons (%j)',
          responseData.dgraphresult
        )
        resolve({ success: true, data: responseData.dgraphresult })
      }) // then
    }) // promise
  },
  async getPrimaryWorkVideos() {
    console.log(
      'getPrimaryWorkVideos user got called (%s)',
      this.primaryuser.uid
    )
    let pUser = this.primaryuser
    // console.log(secondaryUser)
    return new Promise((resolve) => {
      const aStore = adminStore()
      let query = `{
        query(func: uid(${pUser.uid})) {
          uid
          workHelp: pu_hasWorkHelp {
            uid
            dw_created
            dw_title
            dw_video
            dw_videoimage
          }
        }
      } `
      let pLoad = {
        query: encodeLoad(query),
        p1: aStore.sid,
        memasp: true
      }
      console.log(query)
      let instance = aStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getPrimaryWorkVideos', pLoad).then((response) => {
        console.log(response)
        let responseData = response.data
        console.log(
          'getPrimaryWorkVideos respons (%j)',
          responseData.dgraphresult
        )
        resolve({ success: true, data: responseData.dgraphresult })
      }) // then
    }) // promise
  },
  async getSecondaryUsers() {
    console.log('getSecondaryUsers user got called (%s)', this.primaryuser.uid)
    let pUser = this.primaryuser
    console.log(pUser)
    return new Promise((resolve) => {
      const aStore = adminStore()
      let query = `{
        query(func: uid(${pUser.uid})) {
          uid
          secondaryUser: ~su_hasPrimaryUser {
						uid
            su_displayName
    				su_email
    				su_phone
            su_skype
            su_picture
            su_address
            su_postcode
            su_place
    			  su_emergency_contact
            su_emergency_phone
            su_agrementtype
          }
        }
      } `
      let pLoad = {
        query: encodeLoad(query),
        p1: aStore.sid,
        memasp: true
      }
      console.log(query)
      let instance = aStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getSecondaryUsers', pLoad).then((response) => {
        console.log(response)
        let responseData = response.data
        this.setEngageUser(responseData.dgraphresult[0])
        console.log('getSecondaryUsers respons (%j)', responseData.dgraphresult)
        resolve({ success: true, data: responseData.dgraphresult[0] })
      }) // then
    }) // promise
  },
  async processJWT(payload) {
    console.log('processJWT got called ! (%j)', payload)
    return new Promise((resolve) => {
      if (payload) {
        console.log(payload)
        let load = {
          userEmail: payload.pu_email,
          // bearerToken: token,
          email: payload.pu_email,
          name: payload.pu_name,
          picture: payload.pu_picture,
          displayName: payload.pu_displayName,
          service: payload.pu_service,
          language: payload.pu_language,
          userid: payload.pu_userid,
          phone: payload.pu_phone,
          skype: payload.pu_skype,
          created: payload.pu_created,
          logincode: payload.pu_memas_login_code,
          userName: payload.pu_displayName
        }
        this.memasLogin(load).then((result) => {
          console.log('memasLogin returned (%j)', result)
          if (result.success) {
            this.primaryuser = payload
          }
          resolve(result)
        })
      } else {
        // if payload
        resolve({ success: true })
      }
    }) // promise
  },
  setEngageUser(payload) {
    let aStore = adminStore()
    let locale = aStore.lang
    console.log('setEngageUser got called (%s) (%j)', locale, payload)
    let user = payload
    console.log('ENGAGE USER (%j)', user)
    for (let i = 0; i < user.secondaryUser.length; i++) {
      let secondaryUser = user.secondaryUser[i]
      console.log('secondaryUser (%j)', secondaryUser)
      let agreementType = secondaryUser.su_agrementtype
      let memasAgreement = agreementType.substring(0, 6)
      console.log('memasAgreement (%s)', memasAgreement)
      let lang2Digit = get2DigitLangCodes(locale)
      let mAgreement = `${memasAgreement}${lang2Digit}`
      console.log(
        'mAgreement (%s) (%s)',
        mAgreement,
        mAgreement.toUpperCase() === 'ENGAGENB'
      )
      if (mAgreement.toUpperCase() === 'ENGAGENB') {
        this.engageuser = { engageuser: true, lang: lang2Digit }
      } else if (mAgreement.toUpperCase() === 'ENGAGE') {
        this.engageuser = { engageuser: true, lang: lang2Digit }
      } else {
        this.engageuser = { engageuser: false }
      }
    }
  }
}

function get2DigitLangCodes(lang) {
  switch (lang) {
    case 'nb-NO':
    case 'nn-NO':
    case 'nb':
      return 'nb'
    default:
      return 'en'
  }
}

const randomDigits = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9'
]

async function createJwtTokenForClient(payload) {
  console.log('createJwtTokenForClient got called')
  console.log(payload)
  return new Promise((resolve, reject) => {
    const aStore = adminStore()
    try {
      let tokenPayload = {
        userid: payload.userid,
        affiliation: payload.affilitation,
        accessToken: payload.encodedAccessToken,
        email: payload.email || 'NO EMAIL',
        payload: payload
      }
      console.log('tokenPayload : ', tokenPayload)
      getServerSecret()
        .then((result) => {
          console.log('GET SERVER SECRET RETURNED : ')
          console.log(result)
          let publicKey = result.publicKey
          let serverSecret = result.secret
          aStore.sid = result.sid
          tokenPayload.serverSecret = result.secret
          tokenPayload.sessionid = aStore.sid
          let hypJWE = new CreateHypatiaJWE(
            tokenPayload,
            serverSecret,
            null,
            publicKey
          )
          let encrypted = ''

          hypJWE
            .setUp()
            .then(() => {
              hypJWE
                .encrypt(JSON.stringify(tokenPayload))
                .then((encrypts) => {
                  encrypted = encrypts
                  console.log(tokenPayload)
                  console.log('==== ENCRYPTED ====')
                  console.log(encrypted)
                })
                .then(() => {
                  let cryptedPayload = {
                    cryptedPayload: encrypted
                  }
                  console.log('CRYPTED PAYLOAD')
                  console.log(cryptedPayload)
                  hypJWE.sign(cryptedPayload, serverSecret).then((token) => {
                    let returnPayload = {
                      encryptedPayload: encrypted,
                      accessToken: token,
                      publicKey: publicKey,
                      secret: serverSecret,
                      sid: aStore.sid
                    }
                    resolve(returnPayload)
                  }) // sign
                }) // encrypt
                .catch((error) => {
                  console.error(
                    '*** ERROR *** problems encrypting the payload : ' + error
                  )
                  reject({
                    error: true,
                    errorMsg:
                      '*** ERROR *** problems encrypting the payload : ' + error
                  })
                }) // encrypt catch
            }) // setup
            .catch((error) => {
              console.error(
                '*** ERROR *** hypJWE.setup had some problems',
                error
              )
              reject(
                new Error('*** ERROR *** hypJWE.setup had some problems', error)
              )
            })
          /*
          hypJWE.decrypt(encrypted)
            .then(decrypted => {
              console.log('==== DECRYPTED ====')
              console.log(decypted)
            })
            */
        }) // secret
        .catch((error) => {
          console.error(
            '*** ERROR *** problems getting the secret from the server : ' +
              error
          )
          reject({
            error: true,
            errorMsg:
              '*** ERROR *** problems getting the secret from the server : ' +
              error
          })
        }) // getServerSecret - catch
    } catch (error) {
      console.error(
        '*** ERROR **** createJwtTokenForClient, had some problems ' + error
      )
      reject(
        new Error({
          error: error
        })
      )
    }
  }) // promise
}

async function getServerSecret() {
  console.log('GET SERVER Secret GOT CALLED !')
  console.log('=== getServerSecret ')
  return new Promise((resolve, reject) => {
    const aStore = adminStore()

    // let accessClientToken = context.getters.getIdToken
    // console.error('========= idToken !!: ' + accessClientToken)
    // let serverUrl = context.getters.getServerUrl // No context outside action-vuex-store context...
    const sysinfo = aStore.getAxiosConnection2(aStore.serverurl, 'sysinfo/')
    sysinfo
      .get('jwtsecret')
      .then((result) => {
        console.log(
          'JwtSecret er: (' + JSON.stringify(result.data.secret) + ')'
        )
        console.log('getServerSecret returned (%J)', result)
        //  localStorage.setItem('hypsaserversecret', result.data.secret)
        console.log('publicKey: ' + result.data.publicKey)
        resolve(result.data) // {'secret': something }
      })
      .catch((error) => {
        console.error('*** ERROR *** getServerSECRET had some errors:', error)
        reject(
          new Error({
            error: error
          })
        )
      })
  }) // promise
}
