export const userState = {
  test: 'data from pinia userState',
  loggedin: false,
  displayname: 'PINIA DISPLAY NAME',
  accesstoken: '',
  idtoken: '',
  accesstokenexpires: '',
  userid: '',
  email: '',
  language: '',
  affilitation: 'KardeMEMAS',
  serveraccesstoken: '',
  primaryuser: {},
  service: '',
  secondaryuser: {},
  engageuser: {}
}
