import { createRouter, createWebHistory } from 'vue-router'
//import { adminStore } from '@/stores/adminstore.js'
import { userStore } from '@/stores/userstore.js'
import { adminStore } from '@/stores/adminstore.js'

const PrimaryStartPage = () => import('@/views/PrimaryStartPage.vue')
//const PrimaryLandingPage = () => import('@/views/PrimaryLandingPage.vue')
const SelfReporting = () => import('@/views/SelfReporting.vue')

const ErrorPage = () => import('@/views/ErrorPage.vue')
const AboutMemas = () => import('@/views/AboutMemas.vue')
const ContactPage = () => import('@/views/ContactPage.vue')
const PleasurePage = () => import('@/views/PleasurePage.vue')
const DailyHelpPage = () => import('@/views/DailyHelpPage.vue')
const DailyWorkPage = () => import('@/views/DailyWorkPage.vue')
const ToDayPhone = () => import('@/components/calendar/ToDayPhone.vue')
const ToDayNavigation = () =>
  import('@/components/calendar/ToDayNavigation.vue')
const MonthCalendar = () => import('@/components/calendar/MonthCalendar.vue')
const WeekCalendar = () => import('@/components/calendar/WeekCalendar.vue')
const BrainPage = () => import('@/views/BrainPage.vue')
const RehabPage = () => import('@/views/RehabPage.vue')

const MemoryGame = () => import('@/components/games/memorygame/MemoryGame.vue')
const WordGame = () => import('@/components/games/wordgame/WordGame.vue')
const PrimarySettings = () => import('@/views/PrimarySettings.vue')
const PrimaryLandingPage = () =>
  import('@/components/primary/PrimaryLandingPage.vue')
const ImageLibrary = () => import('@/components/pleasure/ImageLibrary.vue')
const VideoLibrary = () => import('@/components/pleasure/VideoLibrary.vue')
const RadioStations = () => import('@/components/pleasure/RadioStations.vue')
const MahjongGame = () => import('@/components/games/mahjong/MahjongGame.vue')
const SudokuGame = () => import('@/components/games/sudoku/SudokuGame.vue')
const CaprinoQuestions = () =>
  import('@/components/brain/caprino/CaprinoQuestions.vue')
const CaprinoMovies = () =>
  import('@/components/brain/caprino/CaprinoMovies.vue')
const WebGames = () => import('@/components/brain/webgames/WebGames.vue')

const SlidingPuzzle = () =>
  import('@/components/games/puzzles/SlidingPuzzle.vue')
const MineSweeperGrid = () =>
  import('@/components/games/minesweeper/MineSweeperGrid.vue')
const Puzzle8 = () => import('@/components/games/puzzles/puzzle-8/Puzzle8.vue')
const GuessNumbers = () =>
  import('@/components/games/guessnumbers/GuessNumbers.vue')
const GamesList = () => import('@/components/games/GamesList.vue')
const BloodPressure = () => import('@/components/transcare/BloodPressure.vue')
const CognitiveLoss = () => import('@/components/transcare/CognitiveLoss.vue')
const Diabetes1 = () => import('@/components/transcare/Diabetes1.vue')
const Diabetes2 = () => import('@/components/transcare/Diabetes2.vue')
const ELearning = () => import('@/components/transcare/ELearning.vue')
const HeartFailure = () => import('@/components/transcare/HeartFailure.vue')
const LivingHelp = () => import('@/components/transcare/LivingHelp.vue')
const SchoolHelp = () => import('@/components/transcare/SchoolHelp.vue')
const SmartWatch = () => import('@/components/transcare/SmartWatch.vue')
const UserAdvice = () => import('@/components/transcare/UserAdvice.vue')
const UserDialog = () => import('@/components/transcare/UserDialog.vue')
const UserExercise = () => import('@/components/transcare/UserExercise.vue')
const UserKnowledge = () => import('@/components/transcare/UserKnowledge.vue')
const UserKols = () => import('@/components/transcare/UserKols.vue')
const UserNutrition = () => import('@/components/transcare/UserNutrition.vue')
const UserObesity = () => import('@/components/transcare/UserObesity.vue')
const UserStatus = () => import('@/components/transcare/UserStatus.vue')
const RehabKnowledge = () => import('@/components/rehab/RehabKnowledge.vue')
const RehabNutrition = () => import('@/components/rehab/RehabNutrition.vue')
const RehabExercise = () => import('@/components/rehab/RehabExercise.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'PrimaryStartPage',
      component: PrimaryStartPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/about',
      name: 'AboutMemas',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: AboutMemas,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/todaynavigation',
      name: 'ToDayNavigation',
      component: ToDayNavigation,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/todayphone',
      name: 'ToDayPhone',
      component: ToDayPhone,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/monthcalendar',
      name: 'MonthCalendar',
      component: MonthCalendar,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/weeknavigation',
      name: 'WeekCalendar',
      component: WeekCalendar,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/selfreporting',
      name: 'SelfReporting',
      component: SelfReporting,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: ContactPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/pleasure',
      name: 'PleasurePage',
      component: PleasurePage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/brain',
      name: 'BrainPage',
      component: BrainPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/dailyhelp',
      name: 'DailyHelpPage',
      component: DailyHelpPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/workhelp',
      name: 'DailyWorkPage',
      component: DailyWorkPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/primarysettings',
      name: 'PrimarySettings',
      component: PrimarySettings,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/landing',
      name: 'PrimaryLandingPage',
      component: PrimaryLandingPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/imagelibrary',
      name: 'ImageLibrary',
      component: ImageLibrary,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/videolibrary',
      name: 'VideoLibrary',
      component: VideoLibrary,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/radiostations',
      name: 'RadioStations',
      component: RadioStations,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/memorygame',
      name: 'MemoryGame',
      component: MemoryGame,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/wordgame',
      name: 'WordGame',
      component: WordGame,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/sudoku',
      name: 'SudokuGame',
      component: SudokuGame,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/mahjong',
      name: 'MahjongGame',
      component: MahjongGame,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/slidingpuzzle',
      name: 'SlidingPuzzle',
      component: SlidingPuzzle,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/minesweeper',
      name: 'MineSweeperGrid',
      component: MineSweeperGrid,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/guessnumbers',
      name: 'GuessNumbers',
      component: GuessNumbers,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/puzzle8',
      name: 'Puzzle8',
      component: Puzzle8,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/games',
      name: 'GamesList',
      component: GamesList,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/webgames',
      name: 'WebGames',
      component: WebGames,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/caprinoq',
      name: 'CaprinoQuestions',
      component: CaprinoQuestions,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/caprinom',
      name: 'CaprinoMovies',
      component: CaprinoMovies,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcbp',
      name: 'BloodPressure',
      component: BloodPressure,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tccl',
      name: 'CognitiveLoss',
      component: CognitiveLoss,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcd1',
      name: 'Diabetes1',
      component: Diabetes1,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcd2',
      name: 'Diabetes2',
      component: Diabetes2,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcel',
      name: 'ELearning',
      component: ELearning,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tchf',
      name: 'HeartFailure',
      component: HeartFailure,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tclh',
      name: 'LivingHelp',
      component: LivingHelp,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcsh',
      name: 'SchoolHelp',
      component: SchoolHelp,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcsw',
      name: 'SmartWatch',
      component: SmartWatch,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcua',
      name: 'UserAdvice',
      component: UserAdvice,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcuk',
      name: 'UserKnowledge',
      component: UserKnowledge,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcuko',
      name: 'UserKols',
      component: UserKols,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcun',
      name: 'UserNutrition',
      component: UserNutrition,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcub',
      name: 'UserObesity',
      component: UserObesity,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcus',
      name: 'UserStatus',
      component: UserStatus,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcud',
      name: 'UserDialog',
      component: UserDialog,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcex',
      name: 'UserExercise',
      component: UserExercise,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcrhbpg',
      name: 'RehabPage',
      component: RehabPage,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcrhbpgnut',
      name: 'RehabNutrition',
      component: RehabNutrition,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcrhbpgknow',
      name: 'RehabKnowledge',
      component: RehabKnowledge,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/tcrhbpgexec',
      name: 'RehabExercise',
      component: RehabExercise,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/*',
      name: 'PrimaryLandingPage',
      component: PrimaryLandingPage
    }
    /*,
    {
      path: '/Error',
      name: 'ErrorPage',
      component: ErrorPage
    }
    */
  ]
})

/*
router.beforeEach((to, from, next) => {
  // ✅ This will work because the router starts its navigation after
  // the router is installed and pinia will be installed too
  const store = useUserAdminStore()

  if (localStorage.getItem('memaskey')) {
    console.log('WE HAVE USER DATA')
    store.setLoggedIn(true)
    next()
  } else {
    store.setLoggedIn(false)
    next({ name: 'PrimaryLandingPage' })
  }
})
*/

function primaryUserRegistered(to, from, next) {
  let uStore = userStore()
  let aStore = adminStore()
  aStore.setLastInteraction()

  if (uStore.loggedin) {
    next()
    return
  }
  console.log('primaryUserRegistered called')
  console.log('FROM (%j)', from)
  console.log('TO (%j)', to)
  console.log('NEXT (%j)', next)
  if (localStorage.getItem('memaskey') && localStorage.getItem('userid')) {
    console.log(
      'WE HAVE USER DATA (%s) (%s)',
      localStorage.getItem('memaskey'),
      localStorage.getItem('userid')
    )

    aStore.servername = localStorage.getItem('memasserver')
    aStore.registryurl = localStorage.getItem('memasregistry')

    uStore.findPrimaryUser(localStorage.getItem('userid')).then((result) => {
      console.log('findPrimaryUser returned: (%j)', result)
      if (result.success) {
        console.log('=== WE HAVE USER (%j)', result.data)
        uStore.primaryuser = result.data
        uStore.processJWT(result.data).then((res) => {
          console.log('processJWT returne (%j)', res)
          if (res.success) {
            uStore.setLoggedIn(true)
            uStore.getSecondaryUsers().finally(() => {})
            // uStore.displayName = result.data.pu_displayName
            // next()
            next()
          } else {
            console.log('**ERROR** setting processJWT')
            uStore.setLoggedIn(false)
            next({
              name: 'PrimaryLandingPage'
            })
          }
        })
      } else {
        console.log('RESULT FROM GETTING PRIMARY USER IS FALSE (%j)', result)
        next({
          name: 'PrimaryLandingPage'
        })
      }
    })
  } else {
    console.log('NO USER DATA - NEED TO REGISTER')
    uStore.setLoggedIn(false)
    next({ name: 'PrimaryLandingPage' })
  }
}
export default router
